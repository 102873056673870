import openSocket from "socket.io-client";
import { getBackendUrl } from "../config";
function connectToSocket() {
    const token = localStorage.getItem("token");
    const socket = openSocket(getBackendUrl(), {
        pingIntenal: 10000,
        pingTimeout: 5000,
        query: {
            token: JSON.parse(token),
        },
        //autoConnect: true,
        //reconnection: true,
        //reconnectionAttempts: Infinity,
        //reconnectionDelay: 5000,
        //reconnectionDelayMax: 300000,
    });
    
    socket.on("disconnect", (reason) => {
        if (reason === 'io server disconnect') {
            socket.connect();
        }
    });
    
    
    /*socket.on("disconnect", (reason) => {
        console.log("Disconnected from socket");
        console.log(reason);
        });
        
        
        socket.on("connection", () => {
        console.log("Connected to socket");
    });*/
    
    return socket;
}

export default connectToSocket;
